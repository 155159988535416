const CENTS_PER_DOLLAR = 100;

export const formatAsDollars = (value: number, options = { cents: true }): string => {
  const dollarAmount = options.cents ? value / CENTS_PER_DOLLAR : value;
  return `$${dollarAmount.toLocaleString('en-US')}`;
};

export const toLocaleDateString = (value?: string) =>
  value ? new Date(value).toLocaleDateString() : '';

export const capitalize = (text: string): string => {
  return text.split('').reduce((result, char, index) => {
    if (index === 0) return result + char.toUpperCase();
    return result + char.toLowerCase();
  }, '');
};

export const currencyStringToNumber = (currencyString: string): number => {
  if (!currencyString) return 0;
  // Remove the dollar sign and commas, and strip any spaces
  const cleanedString = currencyString.replace(/[$,]/g, '').trim();

  // Convert the cleaned string to a number and multiply by 100 to eliminate the decimal
  const numberValue = Math.round(parseFloat(cleanedString) * 100);

  return numberValue;
};

export const generateRandomString = (length: number = 5): string => {
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  let result = '';
  const charactersLength = characters.length;

  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }

  return result;
};

export default null; // silence a false warning

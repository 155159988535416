import type {
  TasApplicationState,
  TasApplicationPaymentState,
} from '../../types/tuition-assistance';
import type { Intent } from 'tio-ui/utilities';

// TODO: i18n labels - James 2024-09-03
// TODO: define labels and intent enums so the functions don't have to cast - James 2024-09-20
enum TasStateLabel {
  // application states
  'TAS.ApplicationState.PENDING_EVIDENCE_APPROVAL' = 'Pending',
  'TAS.ApplicationState.FULFILLED' = 'Complete',
  'TAS.ApplicationState.ATTEND' = 'Change Requested',

  // unused for labels
  // 'TAS.ApplicationState.CREATED',
  // 'TAS.ApplicationState.DEFINE_COURSES',
  // 'TAS.ApplicationState.PENDING_COURSES_APPROVAL',
  // 'TAS.ApplicationState.COURSES_APPROVED',
  // 'TAS.ApplicationState.COURSES_NOT_APPROVED',
  // 'TAS.ApplicationState.COURSES_REJECTED',
  // 'TAS.ApplicationState.EVIDENCE_APPROVED',
  // 'TAS.ApplicationState.EVIDENCE_NOT_APPROVED',
  // 'TAS.ApplicationState.EVIDENCE_REJECTED',
  // 'TAS.ApplicationState.ABANDONED',

  // payment states
  'TAS.ApplicationPaymentState.REFUND_REQUESTED' = 'In Repayment',

  // unused for labels
  // 'TAS.ApplicationPaymentState.NONE',
  // 'TAS.ApplicationPaymentState.PAID_IN_FULL',
  // 'TAS.ApplicationPaymentState.PAID_IN_PART',
  // 'TAS.ApplicationPaymentState.REFUND_REQUESTED',
  // 'TAS.ApplicationPaymentState.UNPAID',
}

// NB: not comprehensive since anything that consumes an intent should
// have a default fallback
enum TasIntent {
  // application states
  'TAS.ApplicationState.PENDING_EVIDENCE_APPROVAL' = 'primary',
  'TAS.ApplicationState.FULFILLED' = 'success',
  'TAS.ApplicationState.ATTEND' = 'info',

  // payment states
  'TAS.ApplicationPaymentState.REFUND_REQUESTED' = 'warning',
}

export const tasStateLabel = (state: TasApplicationState | TasApplicationPaymentState): string => {
  return TasStateLabel[state as keyof typeof TasStateLabel];
};

export const tasStateIntent = (state: TasApplicationState | TasApplicationPaymentState): Intent => {
  return TasIntent[state as keyof typeof TasIntent];
};
